@import '~bulma/sass/utilities/_all.sass';
$switch-radius: 2rem;
$switch-paddle-offset: -0.2rem;
$switch-focus: none;
$blue: #00AAFF;
$switch-background-active: $blue;

@import '~bulma/bulma.sass';
$primary: $blue;
@import '~bulma-switch/src/sass/index.sass';
.switch[type="checkbox"].is-rounded + label::after, .switch[type="checkbox"].is-rounded + label:after {
  border: 1px solid $blue
}
.switch[type="checkbox"].is-rounded + label::before, .switch[type="checkbox"].is-rounded + label:before {
  border: 1px solid $grey
}
.switch:not(:checked).is-rounded + label::after, .switch:not(:checked).is-rounded + label:after {
  border: 1px solid $grey
}

body {
  margin: 0;
  font-family: 'Nunito', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  .graphic {
    width: 100%;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.main-title{
  color: #00003c;
  font-size: 42px;
  line-height: 52px;
  font-weight: bold;
  @include until($desktop) {
    font-size: 40px;
  }
}
.section {
  padding-top: 1rem;
}

.is-main-columns > .column {
  padding: 0 3rem;
}