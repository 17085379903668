@import '~bulma/sass/utilities/_all.sass';
.navigation {
  $border-width: 1px;
  margin-top: 20px;
  .item {
    $height: 30px;
    display: inline-block;
    font-weight: bold;
    margin-right: 10px;
    border: $border-width solid lightgray;
    color: lightgray;
    text-align: center;
    line-height: $height - 2 * $border-width;
    width: $height;
    height: $height;
    border-radius: 100%;
    &.active {
      background: #00AAFF;
      color: #FFF;
      border: none;
    }
    &.completed {
      color: gray;
      border-color: gray;
    }
  }
  @include until($desktop) {
    text-align: center;
  }
  @include from($desktop) {
    margin-left: calc(50px + 10%);
  }
}