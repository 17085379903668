@import '~bulma/sass/utilities/_all.sass';
.frontImage {
  right: 0;
  width: calc(100% - 50px);

  @include until($desktop) {
    display: none;
  }
}

.backImage {
  top: 25px;
  left:0;
  width: calc(100% - 50px);
  
  @include until($desktop) {
    display: none;
  }
}

@include from($desktop) {
  .image {
    position: absolute;
  }
}
