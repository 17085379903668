@import '~bulma/sass/utilities/_all.sass';
.box {
  border-radius: 3px;
  width: 100%;
}
.box:hover {
  box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.2), 0 0px 0 1px rgba(10, 10, 10, 0.04);
}
@include from($desktop) {
  .level-left h3.is-size-5 {
    width: 365px;
  }
}