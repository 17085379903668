@import '~bulma/sass/utilities/_all.sass';
.module-form {
  @include from($desktop) {
    max-height: 50vh;
    position: relative;
    overflow: scroll;
  }

}

.module-form + * {
  margin-top: 20px;
}

form {
  height: auto;
}