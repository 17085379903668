.size {
  .select {
    height: 50px;
    width: 100%;
    select {
      border-radius: 50px;
      height: 50px;
      width:100%;
      padding: 12px 12px 12px 18px;
    }
  }
  .help {
    margin-top:10px;
    font-size: 0.9em;
    color: darkgray;
  }

  .rc-slider-mark-text {
    display: none;
    &.rc-slider-mark-text-active{
      display: block;
      font-size: 1.5em;
      pointer-events: none;
      top: -60px;
      width: 100%;
      font-weight: bold;
    }
  }
  .slider-box {
    padding:60px 30px 20px;
    border: 1px solid lightgray;
    border-radius: 25px;
  }
  .size-price {
    text-align: center;
    margin-top: 20px;
  }

  .bottom {
    margin-top: 80px
  }
  .datacenter {
    margin: 20px auto;
    width: 100%;
    label input {
      margin-left: 20px;
      margin-right: 5px;
    }
    .control {
      text-align: center;
    }

  }
}