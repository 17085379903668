.header {
  display: flex;
  justify-content: center;
  -webkit-box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.16);
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.16);
  padding: 20px 0;
}

.header .logo {
  height: 50px;
}