.field input{
  border-radius: 25px;
  padding: 12px 12px 12px 18px;
  height: 50px;
  margin-bottom: 10px;
}

.columns.is-password-columns {
  margin-bottom: 0;
  .column {
    padding-bottom: 0;;
  }
}