.cta-button {
    $height: 50px;
    text-align: center;
    display: inline-block;
    height: $height;
    line-height: $height;
    background-color: #39C2FF;
    background-image: linear-gradient(180deg, #39C2FF 46%, #00AAFF 46%);
    border-radius: 30px 30px 30px 30px;
    font-weight: 600;
    color: #FFF;
    border: none;
    width: 100%;
    cursor: pointer;

    &:hover {
        background-image: none;
        color: #FFF;
    }
    &.final {
        background: #00D6C0;
        background-image: linear-gradient(180deg, #00D6C0 46%, #04CAB5 46%);
        &:hover {
            background-image: none;
        }
    }
}
a.cta-button:disabled, a.cta-button[disabled] {
    pointer-events: none;
    
    background-image: linear-gradient(180deg, lighten(#39C2FF, 20) 46%, lighten(#00AAFF, 20) 46%);
  }